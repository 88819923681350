<template>
  <v-app>
    <v-main>

      <div class="background" v-if="showBackground">
        <Background @showTextbarEvent="handleShowTextbar" @onload="handleOnload" />
      </div>

      <transition name="fade" mode="out-in">
        <div class="loadingContainer" v-if="isLoading">
          <div class="loading">
            <span></span>
            <span></span>
            <span></span>
            <span></span>
          </div>
        </div>
      </transition>

      <div class="tempInfo">网站建设中...</div>

      <transition name="mainContentFade">
        <div class="mainContent" v-if="showMainContent">
          <div class="Textbar-container">
            <transition name="fade-vertical">
              <Textbar v-if="showTextbar" :message="textbarMessage" :textbarWarning="textbarWarning" />
            </transition>
          </div>
          <pageFooter />
          <Contact @showTextbarEvent="handleShowTextbar" />
          <Music @showTextbarEvent="handleShowTextbar" />
          <Links @showTextbarEvent="handleShowTextbar" @showMorelinksEvent="handleShowMorelinks" />
          <Welcome />
        </div>
      </transition>

      <transition name="morelinksFade">
        <div class="morelinks" v-if="showMorelinks">
          <Morelinks @backToMainpageEvent="handleBackToMainpage" />
        </div>
      </transition>

    </v-main>
  </v-app>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import pageFooter from './components/pageFooter.vue';
import Contact from './components/Contact.vue';
import Background from './components/Background.vue';
import Textbar from './components/Textbar.vue';
import Music from './components/Music.vue';
import Links from './components/Links.vue';
import Welcome from './components/Welcome.vue';
import Morelinks from './components/Morelinks.vue';

interface textbarEventObject {
  timeout?: number,
  textbarWarning: boolean,
  message: string
}

interface onloadEventObject {
  isOnload: boolean
}

export default defineComponent({
  name: 'App',

  components: {
    Background,
    Contact,
    pageFooter,
    Textbar,
    Music,
    Links,
    Welcome,
    Morelinks
  },

  data(): {
    showTextbar: boolean;
    textbarWarning: boolean;
    textbarMessage: string;
    timeoutID: number | undefined;
    isLoading: boolean,
    showMainContent: boolean,
    showMorelinks: boolean,
    showBackground: boolean
  } {
    return {
      showTextbar: false,
      textbarWarning: false,
      textbarMessage: 'message',
      timeoutID: undefined,
      isLoading: true,
      showMainContent: true,
      showMorelinks: false,
      showBackground: true
    };
  },
  methods: {
    handleShowTextbar(eventObject: textbarEventObject) {
      const timeout = eventObject.timeout ?? 3000;

      if (this.timeoutID !== undefined) {
        // 清除正在运行的定时器
        clearTimeout(this.timeoutID);
        this.timeoutID = undefined;

        // 50毫秒后关闭当前Textbar
        setTimeout(() => {
          this.showTextbar = false;
          // 20毫秒后重新显示新的Textbar
          setTimeout(() => {
            this.textbarMessage = eventObject.message;
            this.textbarWarning = eventObject.textbarWarning;
            this.showTextbar = true;
            this.timeoutID = setTimeout(() => {
              this.showTextbar = false;
              this.timeoutID = undefined;
            }, timeout);
          }, 20)
        }, 50);

      } else {
        this.textbarMessage = eventObject.message;
        this.textbarWarning = eventObject.textbarWarning;
        this.showTextbar = true;
        this.timeoutID = setTimeout(() => {
          this.showTextbar = false;
          this.timeoutID = undefined;
        }, timeout);
      }
    },
    beforeUnmount() {
      if (this.timeoutID !== undefined) {
        clearTimeout(this.timeoutID);
      }
    },
    handleOnload(eventObject: onloadEventObject) {
      const isOnload = eventObject.isOnload;
      if (!isOnload) {
        setInterval(() => {
          this.isLoading = false;
        }, 500)
        // this.isLoading = false;

      } else {
        this.isLoading = true;
      }
    },
    handleShowMorelinks() {
      this.showMainContent = false;
      this.showMorelinks = true;
    },
    handleBackToMainpage() {
      this.showMorelinks = false;
      this.showMainContent = true;
    }
  }
})
</script>


<style>
.tempInfo {
  width: 20%;
  height: 5%;
  position: fixed;
  bottom: 5%;
  left: 50%;
  transform: translateX(-50%);
  color: white;
  text-align: center;
  z-index: 999;
}

.loadingContainer {
  position: fixed;
  top: 0%;
  left: 0%;
  width: 100vw;
  height: 100vh;
  background-color: rgba(20, 20, 20, 1);
  z-index: 99998;
}

.loading {
  position: absolute;
  top: 50%;
  left: 50%;
  border-radius: 50%;
  height: 96px;
  width: 96px;
  animation: rotate_3922 1.2s linear infinite;
  background-color: #9b59b6;
  background-image: linear-gradient(#9b59b6, #84cdfa, #5ad1cd);
  z-index: 99999;
}

.loading span {
  position: absolute;
  border-radius: 50%;
  height: 100%;
  width: 100%;
  background-color: #9b59b6;
  background-image: linear-gradient(#9b59b6, #84cdfa, #5ad1cd);
}

.loading span:nth-of-type(1) {
  filter: blur(5px);
}

.loading span:nth-of-type(2) {
  filter: blur(10px);
}

.loading span:nth-of-type(3) {
  filter: blur(25px);
}

.loading span:nth-of-type(4) {
  filter: blur(50px);
}

.loading::after {
  content: "";
  position: absolute;
  top: 10px;
  left: 10px;
  right: 10px;
  bottom: 10px;
  background-color: rgba(20, 20, 20, 1);
  border: solid 5px rgba(20, 20, 20, 1);
  border-radius: 50%;
}

@keyframes rotate_3922 {
  from {
    transform: translate(-50%, -50%) rotate(0deg);
  }

  to {
    transform: translate(-50%, -50%) rotate(360deg);
  }
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 1.2s;
}

.fade-enter,
.fade-leave-to {
  opacity: 0;
}

.mainContentFade-enter-active,
.mainContentFade-leave-active {
  transition: opacity 0.5s;
}

.mainContentFade-enter,
.mainContentFade-leave-to {
  opacity: 0;
}

.morelinksFade-enter-active,
.morelinksFade-leave-active {
  transition: opacity 0.5s;
}

.morelinksFade-enter,
.morelinksFade-leave-to {
  opacity: 0;
}
</style>
