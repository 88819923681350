import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "Welcome" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("div", { class: "welcomeTop" }, [
      _createElementVNode("div", { class: "welcomeInfo" }),
      _createElementVNode("div", { class: "weather" })
    ], -1),
    _createElementVNode("div", { class: "welcomeBottom" }, null, -1)
  ])))
}