<template>
    <div class="Contact">
        <div class="ContactInfos">
            <div class="ContactInfosLeft">
                <div class="personalIcon"><img src="" alt=""></div>
                <span>Contact Me</span>
            </div>
            <div class="ContactInfosRight">
                <span>{{ contactMessage }}</span>
            </div>
        </div>
        <div class="ContactIcons">
            <div class="iconsContainer" v-for="(icon, index) in socialLinks" :key="icon.id"
            :style="{ position: 'absolute', left: (5 + 12 * index) + '%'}">
            <div class="iconsImage" :style="{ backgroundImage: `url('${icon.imgUrl}')` }" :class="{ shrink: icon.isShrink }"
            @click="toUrl(icon.url)" @mouseover="showContactMessage(icon.text)" @mouseout="showDefaultContactMessage()"
            @mousedown="icon.isShrink = true" @mouseup="icon.isShrink = false" @mouseleave="icon.isShrink = false"></div>
            </div>
        </div>
    </div>
</template>


<script lang="ts">

import { defineComponent } from 'vue';

export default defineComponent({
    name: 'Contact',
    data() {
        return {
            contactMessage: '通过这里联系我吧~',
            socialLinks: [
                {id: 'Github', imgUrl: 'https://hanxu.fun/images/icons/github.png', url: 'https://github.com/A100102', text: 'Github', isShrink: false},
                {id: 'Bilibili', imgUrl: 'https://hanxu.fun/images/icons/bilibili.png', url: 'https://space.bilibili.com/36003591', text: 'Bilibili', isShrink: false},
                {id: 'QQ', imgUrl: 'https://hanxu.fun/images/icons/qq.png', url: 'https://user.qzone.qq.com/1185916088', text: 'QQ', isShrink: false},
                {id: 'Twitter', imgUrl: 'https://hanxu.fun/images/icons/twitter.png', url: '/', text: 'Twitter', isShrink: false},
                {id: 'Telegram', imgUrl: 'https://hanxu.fun/images/icons/telegram.png', url: 'https://t.me/handsomeliu1', text: 'Telegram', isShrink: false}
            ],
        };
    },
    methods: {
        toUrl(url: string) {
            if (url == 'https://t.me/handsomeliu1') {
                this.$emit('showTextbarEvent', {'timeout': 3000, 'message': '这个不可以哦~', 'textbarWarning': true});
            } else if (url === '/') {
                this.$emit('showTextbarEvent', {'timeout': 3000, 'message': '这个不可以哦~', 'textbarWarning': true});
            } else if (url == 'https://github.com/A100102') {
                this.$emit('showTextbarEvent', {'timeout': 3000, 'message': '暂时还不行呢~', 'textbarWarning': true});
            } else {
                window.open(url, '_blank');
            }
        },
        showContactMessage(message: string) {
            this.contactMessage = message;
        },
        showDefaultContactMessage() {
            this.contactMessage = '通过这里联系我吧~';
        }
    }
})

</script>


<style scoped>

.Contact {
    position: absolute;
    top: 10rem;
    right: 10%;
    width: 30%;
    height: 6rem;
    background-color: rgba(205, 205, 205, 0.5);
    border-radius: 10px;
    z-index: 999;
}

.Contact:hover .ContactInfosRight {
    display: flex;
}

.ContactInfos {
    position: absolute;
    border-top-left-radius: inherit;
    border-top-right-radius: inherit;
    top: 0%;
    left: 0%;
    width: 100%;
    height: 50%;
}

.personalIcon {
    width: 36px;
    height: 36px;
    border-radius: 50%;
    position: absolute;
    top: 50%;
    left: 5%;
    transform: translateY(-50%);
    background-image: url('https://hanxu.fun/images/icons/icon1.jpg');
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
}

.ContactInfosLeft {
    display: flex;
    align-items: center;
    position: absolute;
    height: 100%;
    width: 50%;
    border-radius: inherit;
    top: 0%;
    left: 0%;
}

.ContactInfosLeft span {
    font-size: 20px;
    padding-left: 60px;
    color: rgba(20, 20, 20, 0.9);
}

.ContactInfosRight {
    display: none;
    align-items: center;
    text-align: right;
    position: absolute;
    height: 100%;
    width: 45%;
    top: 0%;
    right: 0%;
}

.ContactInfosRight span {
    font-size: 20px;
    color: rgba(20, 20, 20, 0.9);
}

.ContactIcons {
    width: 100%;
    height: 50%;
    position: absolute;
    bottom: 0%;
    left: 0%;
    border-bottom-left-radius: inherit;
    border-bottom-right-radius: inherit;
}

.iconsContainer {
    height: 32px;
    width: 32px;
    top: 50%;
    transform: translateY(-50%);
    cursor: pointer;
}

.iconsImage {
    height: 30px;
    width: 30px;
    position: absolute;
    top: 1px;
    left: 1px;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
}

.iconsImage:hover {
    height: 32px;
    width: 32px;
    position: absolute;
    top: 0%;
    left: 0%;
    transition: all 0.1s ease;

}

.iconsImage.shrink {
    height: 28px;
    width: 28px;
    position: absolute;
    top: 2px;
    left: 2px;
    transition: all 0.1 ease;
}


</style>