<template>
    <div class="morelinks">
        <div class="eachlink" v-for="(icon, index) in LinksInfo" :key="icon.id" :id="`Link${index + 1}`">
            <div class="eachlinkContainer" :style="{
                top: `${Math.floor((index) / linkcols) * 15}rem`,
                left: `${((index) % linkcols) * 15}rem`
                }">
                <div class="linkContainer" :class="{ shrink: icon.isShrink }"
                @mousedown="icon.isShrink = true" @mouseup="icon.isShrink = false" @mouseleave="icon.isShrink = false"
                @click="handleLinkContainerClickEvent(icon.url)">
                    <div class="linkText">
                        <img :src="icon.imgUrl" :alt="icon.id" class="LinkImg">
                        &nbsp;&nbsp;
                        <span>{{ icon.name }}</span>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>


<script lang="ts">

import { defineComponent } from 'vue';

export default defineComponent({
    name: 'Morelinks',
    data() {
        return {
            LinksInfo: [
                { id: 'Back', name: '返回', imgUrl: 'https://hanxu.fun/images/icons/back.png', url: '--back', isShrink: false },
                { id: 'Blog', name: '博客', imgUrl: 'https://hanxu.fun/images/icons/blog.png', url: 'https://blog.hanxu.fun/', isShrink: false },
                { id: 'Pan', name: '网盘', imgUrl: 'https://hanxu.fun/images/icons/pan.png', url: 'https://pan.hanxu.fun/', isShrink: false },
                { id: 'WebGIS-Openlayers', name: 'Openlayers', imgUrl: 'https://hanxu.fun/images/icons/openlayers.png', url: 'https://webgis.hanxu.fun/openlayers/', isShrink: false },
                { id: 'WebGIS-Amap', name: '高德 JS API', imgUrl: 'https://hanxu.fun/images/icons/amap.png', url: 'https://webgis.hanxu.fun/amap/', isShrink: false },
                { id: 'Music', name: '音乐', imgUrl: 'https://hanxu.fun/images/icons/music.png', url: '--music', isShrink: false },
                { id: 'Nav', name: '导航页', imgUrl: 'https://hanxu.fun/images/icons/nav.png', url: 'https://hanxu.fun/nav/', isShrink: false },
                { id: 'Test1', name: '测试', imgUrl: 'https://hanxu.fun/images/icons/more.png', url: '--more', isShrink: false },
                { id: 'Test2', name: '测试', imgUrl: 'https://hanxu.fun/images/icons/more.png', url: '--more', isShrink: false }
            ],
            linkcols: 0,
        }
    },
    methods: {
        calcLinkcols() {
            const rootElement = document.documentElement;
            const rem = parseFloat(getComputedStyle(rootElement).fontSize);
            const vw = window.innerWidth;
            this.linkcols = Math.floor(vw / rem / 15);
            console.log(this.linkcols);
        },
        handleLinkContainerClickEvent(url: string) {
            if ( url.startsWith('--') ) {
                if ( url == '--back' ) {
                    this.$emit('backToMainpageEvent');
                }
            } else if ( url.startsWith('http') ) {
                window.open(url, '_blank');
            }
        }
    },
    mounted() {
        this.calcLinkcols();
        window.addEventListener('resize', this.calcLinkcols);
    }

})

</script>


<style scoped>

.morelinks {
    position: absolute;
    width: 100vw;
    height: 100vh;
    top: 0%;
    left: 0%;
    backdrop-filter: blur(10px) brightness(90%);
    background-color: rgba(205, 205, 205, 0.3);
    border-radius: 0px
}

.eachlinkContainer {
    position: absolute;
    white-space: nowrap;
    user-select: none;
    width: 15rem;
    height: 15rem;
}

.linkContainer {
    top: 50%;
    left: 50%;
    position: absolute;
    transform: translate(-50%, -50%);
    width: 60%;
    height: 50%;
    background-color: rgba(235, 235, 235, 0.5);
    backdrop-filter: blur(10px);
    border-radius: 10px;
}

.shrink {
    width: 57%;
    height: 47%;
}

.linkContainer:hover {
    background-color: rgba(255, 255, 255, 0.6);
    transition: all 0.2s ease;
    cursor: pointer;
}

.linkText {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    align-items: center;
    justify-items: center;
    span {
        font-size: 1.2rem;
    }
}

.LinkImg {
    width: 1.5rem;
    height: 1.5em;
}

</style>