<template>
    <div class="Welcome">
        <div class="welcomeTop">
            <div class="welcomeInfo">
                
            </div>
            <div class="weather">

            </div>
        </div>
        <div class="welcomeBottom">

        </div>
    </div>
</template>


<script lang="ts">

import { defineComponent } from 'vue';

export default defineComponent({
    name: 'Welcome',
    data() {
        return {

        }
    },
    methods: {

    }
})

</script>


<style scoped>

.Welcome {
    width: 30%;
    height: 28rem;
    position: absolute;
    top: 10rem;
    left: 5%;
}

.welcomeTop {
    width: 100%;
    height: 6rem;
    position: absolute;
    top: 0%;
    left: 0%;
}

.welcomeBottom {
    width: 100%;
    height: 17rem;
    position: absolute;
    top: 11rem;
    left: 0%;
    /* border: 1px solid #000000; */
}

.welcomeInfo {
    width: 30%;
    height: 100%;
    position: absolute;
    top: 0%;
    left: 0%;
    /* border: 1px solid #000000; */
}

.weather {
    width: 50%;
    height: 100%;
    position: absolute;
    top: 0%;
    left: 50%;
    /* border: 1px solid #000000; */
}

</style>