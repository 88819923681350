import { createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "Footer" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("span", null, [
      _createElementVNode("span", {
        onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.clickToBilibili && _ctx.clickToBilibili(...args))),
        style: {"cursor":"pointer"}
      }, " Copyright © 2024 "),
      _cache[2] || (_cache[2] = _createTextVNode("   ")),
      _createElementVNode("span", {
        onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.clickToBeian && _ctx.clickToBeian(...args))),
        style: {"cursor":"pointer"}
      }, " 蜀ICP备2024067019号-2 ")
    ])
  ]))
}