<template>
    <div class="Textbar">
        <img src="https://hanxu.fun/images/icons/warning.png" alt="warningIcon" style="width: 22px; height: 22px;" v-if="textbarWarning">
        &nbsp;&nbsp;{{ message }}
    </div>
</template>


<script lang="ts">

import { defineComponent } from 'vue';

export default defineComponent({
    name: 'Textbar',
    props: {
        timeout: {
            type: Number,
            default: 5000
        },
        message: {
            type: String,
        },
        textbarWarning: {
            type: Boolean,
        }
    },
    data() {
        return {

        }
    },
    methods: {

    }

})

</script>


<style>

.Textbar {
    width: 100%;
    height: 3rem;
    position: absolute;
    top: 20%;
    left: 0%;
    border-radius: 10px;
    z-index: 999;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #000000;
    background-color: rgba(200, 200, 200, 0.6);
    pointer-events: none;
}

.Textbar::before {
    content: "";
    position: absolute;
    top: 0%;
    left: 0%;
    bottom: 0%;
    right: 0%;
    border-radius: 10px;
    background-color: rgba(200, 200, 200, 0.2);
    filter: blur(8px);
    z-index: 998;
    pointer-events: none;
}

.Textbar-container {
  position: fixed;
  width: 300px;
  height: 20%;
  top: 5%;
  left: 50%;
  transform: translateX(-50%);
  z-index: 999;
}

.fade-vertical-enter-active,
.fade-vertical-leave-active {
  transition: opacity 0.5s ease, transform 0.5s ease;
}

.fade-vertical-enter-from,
.fade-vertical-leave-to {
  opacity: 0;
  transform: translateY(-20px);
  /* 初始位置向下偏移 */
}

.fade-vertical-enter-to,
.fade-vertical-leave-from {
  opacity: 1;
  transform: translateY(0);
  /* 目标位置 */
}

</style>

