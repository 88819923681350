import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode } from "vue"

const _hoisted_1 = { class: "Textbar" }
const _hoisted_2 = {
  key: 0,
  src: "https://hanxu.fun/images/icons/warning.png",
  alt: "warningIcon",
  style: {"width":"22px","height":"22px"}
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.textbarWarning)
      ? (_openBlock(), _createElementBlock("img", _hoisted_2))
      : _createCommentVNode("", true),
    _createTextVNode("   " + _toDisplayString(_ctx.message), 1)
  ]))
}