import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle } from "vue"

const _hoisted_1 = { class: "MusicContainer" }
const _hoisted_2 = ["src"]
const _hoisted_3 = { class: "MusicContainerTop" }
const _hoisted_4 = { class: "musicText" }
const _hoisted_5 = {
  class: "scrollingText",
  id: "scrollingText",
  ref: "scrollingText"
}
const _hoisted_6 = { class: "amplifyIconContainer" }
const _hoisted_7 = { class: "timeInfo" }
const _hoisted_8 = { class: "MusicContainerBottom" }
const _hoisted_9 = { class: "iconsContainer" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.musicLength > 0)
      ? (_openBlock(), _createElementBlock("audio", {
          key: 0,
          src: _ctx.musicList![(_ctx.currentMusicIndex).toString()].src,
          style: {"display":"none"},
          ref: "pageAudio",
          onEnded: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.handleAudioEndedEvent && _ctx.handleAudioEndedEvent(...args))),
          onTimeupdate: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.handleAudioTimeupdate && _ctx.handleAudioTimeupdate(...args))),
          onLoadedmetadata: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.handleLoaded && _ctx.handleLoaded(...args)))
        }, null, 40, _hoisted_2))
      : _createCommentVNode("", true),
    _createElementVNode("div", _hoisted_3, [
      _createElementVNode("div", _hoisted_4, [
        _createElementVNode("div", _hoisted_5, [
          _createElementVNode("span", null, _toDisplayString(_ctx.currentMusicInfo), 1)
        ], 512)
      ]),
      _createElementVNode("div", _hoisted_6, [
        _createElementVNode("div", {
          class: _normalizeClass(["amplifyIcon", { shrink: _ctx.amplifyIconShrink }]),
          onMousedown: _cache[3] || (_cache[3] = 
//@ts-ignore
(...args) => (_ctx.amplifyIconMousedown && _ctx.amplifyIconMousedown(...args))),
          onMouseup: _cache[4] || (_cache[4] = 
//@ts-ignore
(...args) => (_ctx.amplifyIconMouseup && _ctx.amplifyIconMouseup(...args))),
          onMouseleave: _cache[5] || (_cache[5] = 
//@ts-ignore
(...args) => (_ctx.amplifyIconMouseup && _ctx.amplifyIconMouseup(...args))),
          onClick: _cache[6] || (_cache[6] = 
//@ts-ignore
(...args) => (_ctx.handleAmplifyClickEvevt && _ctx.handleAmplifyClickEvevt(...args)))
        }, null, 34)
      ]),
      _createElementVNode("div", {
        class: _normalizeClass(["progressbar", { holdHover: _ctx.ifHoldHover }])
      }, [
        _createElementVNode("div", {
          class: "progress",
          id: "progress",
          onClick: _cache[7] || (_cache[7] = 
//@ts-ignore
(...args) => (_ctx.handleProgressClickEvent && _ctx.handleProgressClickEvent(...args))),
          ref: "progress"
        }, null, 512),
        _cache[22] || (_cache[22] = _createElementVNode("div", {
          class: "fill",
          id: "fill"
        }, null, -1)),
        _createElementVNode("div", {
          class: "dot",
          id: "dot",
          onMousedown: _cache[8] || (_cache[8] = 
//@ts-ignore
(...args) => (_ctx.handleDragdotMousedown && _ctx.handleDragdotMousedown(...args))),
          onMouseup: _cache[9] || (_cache[9] = 
//@ts-ignore
(...args) => (_ctx.handleDragdotMouseup && _ctx.handleDragdotMouseup(...args)))
        }, null, 32)
      ], 2),
      _createElementVNode("div", _hoisted_7, [
        _createElementVNode("span", null, _toDisplayString(`${_ctx.secondToMinute(_ctx.currentTime)} / ${_ctx.secondToMinute(_ctx.totalTime)}`), 1)
      ])
    ]),
    _createElementVNode("div", _hoisted_8, [
      _createElementVNode("div", _hoisted_9, [
        _createElementVNode("div", {
          class: _normalizeClass(["backwardIcon", { shrink: _ctx.backwardIconShrink }]),
          onMousedown: _cache[10] || (_cache[10] = 
//@ts-ignore
(...args) => (_ctx.backwardIconMousedown && _ctx.backwardIconMousedown(...args))),
          onMouseup: _cache[11] || (_cache[11] = 
//@ts-ignore
(...args) => (_ctx.backwardIconMouseup && _ctx.backwardIconMouseup(...args))),
          onMouseleave: _cache[12] || (_cache[12] = 
//@ts-ignore
(...args) => (_ctx.backwardIconMouseup && _ctx.backwardIconMouseup(...args))),
          onClick: _cache[13] || (_cache[13] = 
//@ts-ignore
(...args) => (_ctx.handleBackwardEvent && _ctx.handleBackwardEvent(...args)))
        }, null, 34),
        _createElementVNode("div", {
          class: _normalizeClass(["pauseOrPlayIcon", { shrink: _ctx.pauseOrPlayIconShrink }]),
          onMousedown: _cache[14] || (_cache[14] = 
//@ts-ignore
(...args) => (_ctx.pauseOrPlayIconMousedown && _ctx.pauseOrPlayIconMousedown(...args))),
          onMouseup: _cache[15] || (_cache[15] = 
//@ts-ignore
(...args) => (_ctx.pauseOrPlayIconMouseup && _ctx.pauseOrPlayIconMouseup(...args))),
          onMouseleave: _cache[16] || (_cache[16] = 
//@ts-ignore
(...args) => (_ctx.pauseOrPlayIconMouseup && _ctx.pauseOrPlayIconMouseup(...args))),
          onClick: _cache[17] || (_cache[17] = 
//@ts-ignore
(...args) => (_ctx.togglePausePlay && _ctx.togglePausePlay(...args))),
          style: _normalizeStyle([_ctx.pauseOrPlay ? { backgroundImage: `url('https://hanxu.fun/images/icons/pause.png')` } : { backgroundImage: `url('https://hanxu.fun/images/icons/play.png')` }])
        }, null, 38),
        _createElementVNode("div", {
          class: _normalizeClass(["forwardIcon", { shrink: _ctx.forwardIconShrink }]),
          onMousedown: _cache[18] || (_cache[18] = 
//@ts-ignore
(...args) => (_ctx.forwardIconMousedown && _ctx.forwardIconMousedown(...args))),
          onMouseup: _cache[19] || (_cache[19] = 
//@ts-ignore
(...args) => (_ctx.forwardIconMouseup && _ctx.forwardIconMouseup(...args))),
          onMouseleave: _cache[20] || (_cache[20] = 
//@ts-ignore
(...args) => (_ctx.forwardIconMouseup && _ctx.forwardIconMouseup(...args))),
          onClick: _cache[21] || (_cache[21] = 
//@ts-ignore
(...args) => (_ctx.handleForwardEvent && _ctx.handleForwardEvent(...args)))
        }, null, 34)
      ])
    ])
  ]))
}