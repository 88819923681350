import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { class: "LinksContainer" }
const _hoisted_2 = ["id"]
const _hoisted_3 = ["onMousedown", "onMouseup", "onMouseleave", "onClick"]
const _hoisted_4 = ["src", "alt"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.LinksInfo, (icon, index) => {
      return (_openBlock(), _createElementBlock("div", {
        class: "Links",
        key: icon.id,
        id: `Link${index + 1}`
      }, [
        _createElementVNode("div", {
          class: _normalizeClass(["LinkContainer", { shrink: icon.isShrink }])
        }, [
          _createElementVNode("div", {
            class: "eachLinkContainer",
            onMousedown: ($event: any) => (icon.isShrink = true),
            onMouseup: ($event: any) => (icon.isShrink = false),
            onMouseleave: ($event: any) => (icon.isShrink = false),
            onClick: ($event: any) => (_ctx.goToUrl(icon.url))
          }, [
            _createElementVNode("img", {
              src: icon.imgUrl,
              alt: icon.id,
              class: "LinkImg"
            }, null, 8, _hoisted_4),
            _cache[0] || (_cache[0] = _createTextVNode("     ")),
            _createElementVNode("span", null, _toDisplayString(icon.name), 1)
          ], 40, _hoisted_3)
        ], 2)
      ], 8, _hoisted_2))
    }), 128))
  ]))
}