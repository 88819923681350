<template>
    <div class="backgroundImage" :style="{ 'background-image': `url(${backgroundImageUrl})` }"></div>
</template>


<script lang="ts">

import { defineComponent } from 'vue';

export default defineComponent({
    name: 'Background',
    data(): {
        backgroundImageUrl: string,
        randomNumber: number,
        userAspectRatio: string,
    } {
        return {
            backgroundImageUrl: '',
            randomNumber: 1,
            userAspectRatio: '16-9',   // 16 : 9
            // 2.4 1.77 1.6 1.33 1.25
        };
    },
    methods: {
        getRandomNumber() {
            const randomNumber = Math.floor(Math.random() * 12) + 1; 
            this.randomNumber = randomNumber;
        },
        getRandomImage() {
            const userWidth = window.innerWidth;
            const userHeight = window.innerHeight;
            const userAspectRatio = userWidth / userHeight;
            const backgroundImageRatio = [2.4, 1.77, 1.6, 1.33, 1.25];
            let closest = backgroundImageRatio[0];
            let minDiff = Math.abs(backgroundImageRatio[0] - userAspectRatio);
            for (let i = 1; i < backgroundImageRatio.length; i++) {
                const diff = Math.abs(backgroundImageRatio[i] - userAspectRatio);
                if (diff < minDiff) {
                    closest = backgroundImageRatio[i];
                    minDiff = diff;
                }
            }
            const ratioDict: { [key: string]: string; } = {
                '2.4': 'ultrawide',
                '1.77': '16-9',
                '1.6': '16-10',
                '1.33': '4-3',
                '1.25': '5-4'
            };
            this.userAspectRatio = ratioDict[closest.toString()];
            const bgImg = new Image();
            bgImg.src = `https://hanxu.asia/background/background${this.randomNumber}-${this.userAspectRatio}.webp`;
            bgImg.onload = () => {
                this.backgroundImageUrl = bgImg.src;
                // console.log('背景图片加载成功! 服务器: asia');
                this.$emit('onload', {'isOnload': false});
            };
            bgImg.onerror = () => {
                const bgImg_2 = new Image();
                 bgImg_2.src = `https://hanxu.fun/background/background${this.randomNumber}-${this.userAspectRatio}.webp`;
                 bgImg_2.onload = () => {
                    this.backgroundImageUrl = bgImg_2.src;
                    // console.log('背景图片加载成功! 服务器: fun');
                    this.$emit('onload', {'isOnload': false});
                 };
                 bgImg_2.onerror = () => {
                    console.error('背景图片加载失败!');
                    this.$emit('onload', {'isOnload': true});
                 };
            }
        },
        checkUserScreen() {
            const userScreenRatio = window.innerWidth / window.innerHeight;
            if (userScreenRatio < 1) {
                this.$emit('showTextbarEvent', {'timeout': 5000, 'message': '抱歉，当前页面暂未适配竖屏', 'textbarWarning': true});
            }
        }
    },
    mounted() {
        this.getRandomNumber();
        this.getRandomImage();
        window.addEventListener('resize', this.getRandomImage);
        this.checkUserScreen();
    },
})

</script>


<style scoped>
.backgroundImage {
    position: fixed;
    height: 100%;
    width: 100%;
    top: 0%;
    left: 0%;
    background-size: cover;
    background-position: center;
    z-index: 0;
}
</style>