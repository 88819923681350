<template>
    <div class="MusicContainer">
        <audio v-if="musicLength > 0" :src="musicList![(currentMusicIndex).toString()].src" style="display: none;"
            ref="pageAudio" @ended="handleAudioEndedEvent" @timeupdate="handleAudioTimeupdate"
            @loadedmetadata="handleLoaded"></audio>
        <div class="MusicContainerTop">
            <div class="musicText">
                <div class="scrollingText" id="scrollingText" ref="scrollingText"><span>{{ currentMusicInfo }}</span>
                </div>
            </div>
            <div class="amplifyIconContainer">
                <div class="amplifyIcon" @mousedown="amplifyIconMousedown" @mouseup="amplifyIconMouseup"
                    @mouseleave="amplifyIconMouseup" :class="{ shrink: amplifyIconShrink }"
                    @click="handleAmplifyClickEvevt"></div>
            </div>
            <div class="progressbar" :class="{ holdHover: ifHoldHover }">
                <div class="progress" id="progress" @click="handleProgressClickEvent" ref="progress"></div>
                <div class="fill" id="fill"></div>
                <div class="dot" id="dot" @mousedown="handleDragdotMousedown" @mouseup="handleDragdotMouseup"></div>
            </div>
            <div class="timeInfo">
                <span>{{ `${secondToMinute(currentTime)} / ${secondToMinute(totalTime)}` }}</span>
            </div>
        </div>
        <div class="MusicContainerBottom">
            <div class="iconsContainer">
                <div class="backwardIcon" @mousedown="backwardIconMousedown" @mouseup="backwardIconMouseup"
                    @mouseleave="backwardIconMouseup" :class="{ shrink: backwardIconShrink }"
                    @click="handleBackwardEvent"></div>
                <div class="pauseOrPlayIcon" @mousedown="pauseOrPlayIconMousedown" @mouseup="pauseOrPlayIconMouseup"
                    @mouseleave="pauseOrPlayIconMouseup" :class="{ shrink: pauseOrPlayIconShrink }"
                    @click="togglePausePlay"
                    :style="[pauseOrPlay ? { backgroundImage: `url('https://hanxu.fun/images/icons/pause.png')` } : { backgroundImage: `url('https://hanxu.fun/images/icons/play.png')` }]">
                </div>
                <div class="forwardIcon" @mousedown="forwardIconMousedown" @mouseup="forwardIconMouseup"
                    @mouseleave="forwardIconMouseup" :class="{ shrink: forwardIconShrink }" @click="handleForwardEvent">
                </div>
            </div>
        </div>
    </div>
</template>


<script lang="ts">

interface MusicItem {
    name: string;
    src: string;
}

interface MusicListResponse {
    [key: string]: MusicItem;
}

import { defineComponent } from 'vue';

export default defineComponent({
    name: 'Music',
    data(): {
        backwardIconShrink: boolean,
        forwardIconShrink: boolean,
        pauseOrPlayIconShrink: boolean,
        amplifyIconShrink: boolean,
        pauseOrPlay: boolean,
        currentMusicInfo: string,
        currentMusicIndex: number,
        musicList: MusicListResponse | null,
        musicLength: number,
        scrollPosition: number,
        scrollSpeed: number,
        textElement: HTMLElement | null,
        currentTime: number,
        totalTime: number,
        draggingIntervalID: number | undefined,
        isDragging: boolean,
        dragEndLeft: number | undefined,
        progressWidth: number,
        ifHoldHover: boolean,

    } {
        return {
            backwardIconShrink: false,
            forwardIconShrink: false,
            pauseOrPlayIconShrink: false,
            amplifyIconShrink: false,
            pauseOrPlay: false,   // false => pause, true => play
            currentMusicInfo: '',
            currentMusicIndex: 0,
            musicList: null,
            musicLength: 0,
            scrollPosition: 50,
            scrollSpeed: 0.3,
            textElement: null,
            currentTime: 0,
            totalTime: 0,
            draggingIntervalID: undefined,
            isDragging: false,
            dragEndLeft: undefined,
            progressWidth: 0,
            ifHoldHover: false,
        };
    },
    methods: {
        backwardIconMousedown() {
            this.backwardIconShrink = true;
        },
        backwardIconMouseup() {
            this.backwardIconShrink = false;
        },
        forwardIconMousedown() {
            this.forwardIconShrink = true;
        },
        forwardIconMouseup() {
            this.forwardIconShrink = false;
        },
        pauseOrPlayIconMousedown() {
            this.pauseOrPlayIconShrink = true;
        },
        pauseOrPlayIconMouseup() {
            this.pauseOrPlayIconShrink = false;
        },
        amplifyIconMousedown() {
            this.amplifyIconShrink = true;
        },
        amplifyIconMouseup() {
            this.amplifyIconShrink = false;
        },
        handleAmplifyClickEvevt() {
            this.$emit('showTextbarEvent', { 'timeout': 3000, 'message': '暂时还没有这个功能哦~', 'textbarWarning': true });
        },
        async togglePausePlay() {
            if (this.pauseOrPlay === false) {
                try {
                    await (this.$refs.pageAudio as HTMLAudioElement).play()
                    this.pauseOrPlay = true;
                } catch (error) {
                    this.pauseOrPlay = false;
                    console.error(error);
                }
            } else {
                try {
                    await (this.$refs.pageAudio as HTMLAudioElement).pause();
                    this.pauseOrPlay = false;
                } catch (error) {
                    this.pauseOrPlay = true;
                    console.error(error);
                }
            }
        },
        async fetchMusicList() {
            try {
                const response = await fetch('https://hanxu.fun/api/musiclist/');
                const data: MusicListResponse = await response.json();
                this.musicList = data;
            } catch (error) {
                console.log(error);
            }
        },
        async initPage() {
            await this.fetchMusicList();
            this.musicLength = Object.keys(this.musicList!).length;
            this.currentMusicInfo = this.musicList![(this.currentMusicIndex).toString()].name;

        },
        handleBackwardEvent() {
            try {
                (this.$refs.pageAudio as HTMLAudioElement).pause();
                this.pauseOrPlay = false;
                if (this.currentMusicIndex === 0) {
                    this.currentMusicIndex = this.musicLength - 1;
                } else {
                    this.currentMusicIndex -= 1;
                };
                setTimeout(() => {
                    this.togglePausePlay();
                }, 100)
                this.currentMusicInfo = this.musicList![(this.currentMusicIndex).toString()].name;
                this.scrollPosition = 50;
            } catch (error) {
                console.error(error);

            };

        },
        handleForwardEvent() {
            try {
                (this.$refs.pageAudio as HTMLAudioElement).pause();
                this.pauseOrPlay = false;
                if (this.currentMusicIndex === this.musicLength - 1) {
                    this.currentMusicIndex = 0;
                } else {
                    this.currentMusicIndex += 1;
                };
                setTimeout(() => {
                    this.togglePausePlay();
                }, 100)
                this.currentMusicInfo = this.musicList![(this.currentMusicIndex).toString()].name;
                this.scrollPosition = 50;
            } catch (error) {
                console.error(error);

            };
        },
        updateScrollingText() {
            this.scrollPosition -= this.scrollSpeed;
            if (this.scrollPosition <= -this.textElement!.offsetWidth - 100) {
                this.scrollPosition = 50;
            }
            this.textElement!.style.left = `${this.scrollPosition + 100}px`;

        },
        handleAudioEndedEvent() {
            if (this.currentMusicIndex === this.musicLength - 1) {
                this.togglePausePlay();
            } else {
                this.handleForwardEvent();
                setTimeout(() => {
                    this.togglePausePlay();
                }, 100)
            }
        },
        handleAudioTimeupdate() {
            if (!this.isDragging) {
                if (this.$refs.pageAudio) {
                    this.currentTime = (this.$refs.pageAudio as HTMLAudioElement).currentTime;
                    document.getElementById('fill')!.style.width = `${(this.currentTime / this.totalTime) * 100}%`;
                    document.getElementById('dot')!.style.left = `${(this.currentTime / this.totalTime) * 100}%`
                }
            }
        },
        handleLoaded() {
            if (this.$refs.pageAudio) {
                this.totalTime = (this.$refs.pageAudio as HTMLAudioElement).duration;
            }
        },
        secondToMinute(time: number) {
            const minute = Math.floor(time / 60);
            const second = Math.floor(time % 60);
            const formattedSecond = second.toString().padStart(2, '0');
            return `${minute}:${formattedSecond}`;
        },
        handleProgressClickEvent(event: MouseEvent) {
            if (event.target instanceof HTMLElement) {
                const targetElement = event.target;
                const width = targetElement.offsetWidth;
                const rect = targetElement.getBoundingClientRect();
                const clickX = event.clientX - rect.left;
                this.currentTime = this.totalTime * (clickX / width);
                (this.$refs.pageAudio as HTMLAudioElement).currentTime = this.currentTime;
            } else {

            };
        },
        handleDragdotMousedown() {
            this.isDragging = true;
            this.progressWidth = (this.$refs.progress as HTMLElement).getBoundingClientRect().width;
            document.addEventListener('mousemove', this.handleDragdotMousemove);
            document.addEventListener('mouseup', this.handleDragdotMouseup);
            this.ifHoldHover = true;
        },
        handleDragdotMouseup() {
            this.isDragging = false;
            if (this.dragEndLeft !== undefined) {
                this.currentTime = this.totalTime * (this.dragEndLeft / this.progressWidth);
                (this.$refs.pageAudio as HTMLAudioElement).currentTime = this.currentTime;
            }
            document.removeEventListener('mousemove', this.handleDragdotMousemove);
            document.removeEventListener('mouseup', this.handleDragdotMouseup);
            this.ifHoldHover = false;
            
        },
        handleDragdotMousemove(event: MouseEvent) {
            if (this.isDragging) {
                const progressbarRect = (this.$refs.progress as HTMLElement).getBoundingClientRect();
                const mouseX = event.clientX;
                const dot = document.getElementById('dot')!;
                const fill = document.getElementById('fill')!;
                let left = mouseX - progressbarRect.left;
                if (left <= 0) {
                    dot.style.left = '0%';
                    fill.style.width = '0%';
                } else if (left > progressbarRect.width) {
                    dot.style.left = '100%';
                    fill.style.width = '100%';
                } else {
                    dot.style.left = `${left}px`;
                    fill.style.width = `${left}px`;
                };
                this.dragEndLeft = left;
            }
        }
    },
    async mounted() {
        await this.initPage();
        this.textElement = document.getElementById("scrollingText");
        setInterval(this.updateScrollingText, 10);
    }
})

</script>


<style scoped>
.MusicContainer {
    position: absolute;
    top: 10rem;
    right: 43%;
    width: 12%;
    height: 6rem;
    background-color: rgba(205, 205, 205, 0.5);
    border-radius: 10px;
    z-index: 999;
}

.MusicContainerTop {
    position: absolute;
    top: 0%;
    left: 0%;
    width: 100%;
    height: 50%;
    border-top-left-radius: inherit;
    border-top-right-radius: inherit;
}

.MusicContainerBottom {
    position: absolute;
    bottom: 0%;
    left: 0%;
    width: 100%;
    height: 50%;
    border-bottom-left-radius: inherit;
    border-bottom-right-radius: inherit;
}

.iconsContainer {
    position: absolute;
    top: 0%;
    left: 15%;
    width: 70%;
    height: 100%;
}

.backwardIcon {
    position: absolute;
    left: 2px;
    top: 50%;
    width: 30px;
    height: 30px;
    transform: translateY(-50%);
    background-image: url('https://hanxu.fun/images/icons/backward.png');
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    cursor: pointer;
}

.backwardIcon:hover {
    width: 32px;
    height: 32px;
    left: 1px;
}

.backwardIcon.shrink {
    width: 28px;
    height: 28px;
    left: 3px;
}

.forwardIcon {
    position: absolute;
    right: 2px;
    top: 50%;
    width: 30px;
    height: 30px;
    transform: translateY(-50%);
    background-image: url('https://hanxu.fun/images/icons/forward.png');
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    cursor: pointer;
}

.forwardIcon:hover {
    width: 32px;
    height: 32px;
    right: 1px;
}

.forwardIcon.shrink {
    width: 28px;
    height: 28px;
    right: 3px;
}

.pauseOrPlayIcon {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 30px;
    height: 30px;
    transform: translate(-50%, -50%);
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    cursor: pointer;
}

.pauseOrPlayIcon:hover {
    width: 32px;
    height: 32px;
}

.pauseOrPlayIcon.shrink {
    width: 28px;
    height: 28px;
}

.musicText {
    position: absolute;
    top: 10%;
    left: 50%;
    width: 70%;
    height: 25px;
    transform: translateX(-60%);
    text-align: center;
    white-space: nowrap;
    overflow: hidden;
}

.scrollingText {
    height: 100%;
    position: absolute;
    left: 110%;
}

.amplifyIconContainer {
    position: absolute;
    top: 5%;
    right: 2%;
    width: 25px;
    height: 25px;
}

.amplifyIcon {
    position: absolute;
    top: 2px;
    left: 2px;
    width: 21px;
    height: 21px;
    background-image: url('https://hanxu.fun/images/icons/amplify.png');
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    cursor: pointer;
}

.amplifyIcon:hover {
    width: 23px;
    height: 23px;
    top: 1px;
    left: 1px;
}

.amplifyIcon.shrink {
    width: 19px;
    height: 19px;
    top: 3px;
    left: 3px;
}

.timeInfo {
    position: absolute;
    width: 35%;
    height: 16px;
    bottom: 5%;
    right: 3%;
    display: flex;
    justify-content: center;
    align-items: center;
    white-space: nowrap;
}

.timeInfo span {
    font-size: 13px;
}

.progressbar {
    position: absolute;
    width: 53%;
    height: 16px;
    bottom: 5%;
    left: 5%;
    user-select: none;
}

.progressbar:hover {
    .fill {
        background-color: #fc3c51;
    }

    .dot {
        display: block;
    }

    cursor: pointer;
}

.progressbar .progress {
    width: 100%;
    height: 6px;
    border-radius: 3px;
    background-color: #dadcdf;
    position: absolute;
    top: 50%;
    left: 0%;
    transform: translateY(-50%);
    z-index: 991;

}

.progressbar .fill {
    width: 0%;
    height: 6px;
    border-radius: 3px;
    background-color: #f26c79;
    position: absolute;
    top: 50%;
    left: 0%;
    transform: translateY(-50%);
    pointer-events: none;
    z-index: 992;
}

.progressbar .dot {
    display: none;
    width: 10px;
    height: 10px;
    border-radius: 5px;
    background-color: #FFFFFF;
    position: absolute;
    top: 50%;
    left: 0%;
    transform: translate(-50%, -50%);
    z-index: 993;
}

.holdHover {
    .fill {
        background-color: #fc3c51;
    }

    .dot {
        display: block;
    }
}
</style>