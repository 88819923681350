import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle } from "vue"

const _hoisted_1 = { class: "Contact" }
const _hoisted_2 = { class: "ContactInfos" }
const _hoisted_3 = { class: "ContactInfosRight" }
const _hoisted_4 = { class: "ContactIcons" }
const _hoisted_5 = ["onClick", "onMouseover", "onMousedown", "onMouseup", "onMouseleave"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _cache[1] || (_cache[1] = _createElementVNode("div", { class: "ContactInfosLeft" }, [
        _createElementVNode("div", { class: "personalIcon" }, [
          _createElementVNode("img", {
            src: "",
            alt: ""
          })
        ]),
        _createElementVNode("span", null, "Contact Me")
      ], -1)),
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("span", null, _toDisplayString(_ctx.contactMessage), 1)
      ])
    ]),
    _createElementVNode("div", _hoisted_4, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.socialLinks, (icon, index) => {
        return (_openBlock(), _createElementBlock("div", {
          class: "iconsContainer",
          key: icon.id,
          style: _normalizeStyle({ position: 'absolute', left: (5 + 12 * index) + '%'})
        }, [
          _createElementVNode("div", {
            class: _normalizeClass(["iconsImage", { shrink: icon.isShrink }]),
            style: _normalizeStyle({ backgroundImage: `url('${icon.imgUrl}')` }),
            onClick: ($event: any) => (_ctx.toUrl(icon.url)),
            onMouseover: ($event: any) => (_ctx.showContactMessage(icon.text)),
            onMouseout: _cache[0] || (_cache[0] = ($event: any) => (_ctx.showDefaultContactMessage())),
            onMousedown: ($event: any) => (icon.isShrink = true),
            onMouseup: ($event: any) => (icon.isShrink = false),
            onMouseleave: ($event: any) => (icon.isShrink = false)
          }, null, 46, _hoisted_5)
        ], 4))
      }), 128))
    ])
  ]))
}