<template>
    <div class="Footer">
        <span>
            <span @click="clickToBilibili" style="cursor: pointer;">
            Copyright &copy; 2024
            </span>
            &nbsp;
            <span @click="clickToBeian" style="cursor: pointer;">
                蜀ICP备2024067019号-2
            </span>
        </span>
    </div>
</template>


<script lang="ts">

import { defineComponent } from 'vue';

export default defineComponent({
    name: 'pageFooter',
    data() {
        return {

        };
    },
    methods: {
        clickToBeian() {
            window.open('https://beian.miit.gov.cn/', '_blank');
        },
        clickToBilibili() {
            window.open('https://space.bilibili.com/36003591', '_blank');
        }
    }
})

</script>


<style scoped>
.Footer {
    position: fixed;
    width: 100%;
    height: 2rem;
    left: 0%;
    bottom: 0%;
    display: flex;
    border-radius: 10px;
    justify-content: center;
    align-items: center;
    color: #FFFFFF;
    background-color: rgba(100, 100, 100, 0.5);
    z-index: 998;
}

.Footer span {
    z-index: 999;
}

.Footer::before {
    content: "";
    position: absolute;
    top: 0%;
    left: 0%;
    bottom: 0%;
    right: 0%;
    border-radius: 10px;
    background-color: rgba(100, 100, 100, 0.1);
    filter: blur(8px);
    z-index: 990;

}

</style>