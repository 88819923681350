import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, Transition as _Transition, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "background"
}
const _hoisted_2 = {
  key: 0,
  class: "loadingContainer"
}
const _hoisted_3 = {
  key: 0,
  class: "mainContent"
}
const _hoisted_4 = { class: "Textbar-container" }
const _hoisted_5 = {
  key: 0,
  class: "morelinks"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Background = _resolveComponent("Background")!
  const _component_Textbar = _resolveComponent("Textbar")!
  const _component_pageFooter = _resolveComponent("pageFooter")!
  const _component_Contact = _resolveComponent("Contact")!
  const _component_Music = _resolveComponent("Music")!
  const _component_Links = _resolveComponent("Links")!
  const _component_Welcome = _resolveComponent("Welcome")!
  const _component_Morelinks = _resolveComponent("Morelinks")!
  const _component_v_main = _resolveComponent("v-main")!
  const _component_v_app = _resolveComponent("v-app")!

  return (_openBlock(), _createBlock(_component_v_app, null, {
    default: _withCtx(() => [
      _createVNode(_component_v_main, null, {
        default: _withCtx(() => [
          (_ctx.showBackground)
            ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
                _createVNode(_component_Background, {
                  onShowTextbarEvent: _ctx.handleShowTextbar,
                  onOnload: _ctx.handleOnload
                }, null, 8, ["onShowTextbarEvent", "onOnload"])
              ]))
            : _createCommentVNode("", true),
          _createVNode(_Transition, {
            name: "fade",
            mode: "out-in"
          }, {
            default: _withCtx(() => [
              (_ctx.isLoading)
                ? (_openBlock(), _createElementBlock("div", _hoisted_2, _cache[0] || (_cache[0] = [
                    _createElementVNode("div", { class: "loading" }, [
                      _createElementVNode("span"),
                      _createElementVNode("span"),
                      _createElementVNode("span"),
                      _createElementVNode("span")
                    ], -1)
                  ])))
                : _createCommentVNode("", true)
            ]),
            _: 1
          }),
          _cache[1] || (_cache[1] = _createElementVNode("div", { class: "tempInfo" }, "网站建设中...", -1)),
          _createVNode(_Transition, { name: "mainContentFade" }, {
            default: _withCtx(() => [
              (_ctx.showMainContent)
                ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
                    _createElementVNode("div", _hoisted_4, [
                      _createVNode(_Transition, { name: "fade-vertical" }, {
                        default: _withCtx(() => [
                          (_ctx.showTextbar)
                            ? (_openBlock(), _createBlock(_component_Textbar, {
                                key: 0,
                                message: _ctx.textbarMessage,
                                textbarWarning: _ctx.textbarWarning
                              }, null, 8, ["message", "textbarWarning"]))
                            : _createCommentVNode("", true)
                        ]),
                        _: 1
                      })
                    ]),
                    _createVNode(_component_pageFooter),
                    _createVNode(_component_Contact, { onShowTextbarEvent: _ctx.handleShowTextbar }, null, 8, ["onShowTextbarEvent"]),
                    _createVNode(_component_Music, { onShowTextbarEvent: _ctx.handleShowTextbar }, null, 8, ["onShowTextbarEvent"]),
                    _createVNode(_component_Links, {
                      onShowTextbarEvent: _ctx.handleShowTextbar,
                      onShowMorelinksEvent: _ctx.handleShowMorelinks
                    }, null, 8, ["onShowTextbarEvent", "onShowMorelinksEvent"]),
                    _createVNode(_component_Welcome)
                  ]))
                : _createCommentVNode("", true)
            ]),
            _: 1
          }),
          _createVNode(_Transition, { name: "morelinksFade" }, {
            default: _withCtx(() => [
              (_ctx.showMorelinks)
                ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
                    _createVNode(_component_Morelinks, { onBackToMainpageEvent: _ctx.handleBackToMainpage }, null, 8, ["onBackToMainpageEvent"])
                  ]))
                : _createCommentVNode("", true)
            ]),
            _: 1
          })
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}