<template>
    <div class="LinksContainer">

        <div class="Links" v-for="(icon, index) in LinksInfo" :key="icon.id" :id="`Link${index + 1}`">
            <div class="LinkContainer" :class="{ shrink: icon.isShrink }">
                <div class="eachLinkContainer"
                    @mousedown="icon.isShrink = true" @mouseup="icon.isShrink = false"
                    @mouseleave="icon.isShrink = false" @click="goToUrl(icon.url)">
                    <img :src="icon.imgUrl" :alt="icon.id" class="LinkImg">
                    &nbsp;&nbsp;&nbsp;
                    <span>{{ icon.name }}</span>
                </div>
            </div>
        </div>
    </div>
</template>


<script lang="ts">

import { defineComponent } from 'vue';

export default defineComponent({
    name: 'Links',
    data() {
        return {
            LinksInfo: [
                { id: 'Blog', name: '博客', imgUrl: 'https://hanxu.fun/images/icons/blog.png', url: 'https://blog.hanxu.fun/', isShrink: false },
                { id: 'Pan', name: '网盘', imgUrl: 'https://hanxu.fun/images/icons/pan.png', url: 'https://pan.hanxu.fun/', isShrink: false },
                { id: 'WebGIS', name: 'WebGIS', imgUrl: 'https://hanxu.fun/images/icons/webgis.png', url: 'https://webgis.hanxu.fun/openlayers/', isShrink: false },
                { id: 'Music', name: '音乐', imgUrl: 'https://hanxu.fun/images/icons/music.png', url: '--music', isShrink: false },
                { id: 'Nav', name: '导航页', imgUrl: 'https://hanxu.fun/images/icons/nav.png', url: 'https://hanxu.fun/nav/', isShrink: false },
                { id: 'More', name: '更多', imgUrl: 'https://hanxu.fun/images/icons/more.png', url: '--more', isShrink: false }
            ]
        };
    },
    methods: {
        goToUrl(url: string) {
            if (url === '--music') {
                this.$emit('showTextbarEvent', { 'timeout': 3000, 'message': '暂时还没有这个功能哦~', 'textbarWarning': true });
            } else if (url === '--more') {
                this.$emit('showMorelinksEvent');
            } else {
                window.open(url, '_blank');
            }
        }
    },
    mounted() {
        // setInterval(() => {
        //     console.log(this.LinksInfo[0].isShrink);
        // }, 1000);
    }
})

</script>


<style scoped>
.LinksContainer {
    position: absolute;
    width: 45%;
    height: 17rem;
    top: 21rem;
    right: 10%;
}

.Links {
    position: absolute;
    width: 33.3%;
    height: 50%;
}

.LinkContainer {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

#Link1 {
    top: 0%;
    left: 0%;
}

#Link1 .LinkContainer {
    left: 45%;
}

#Link2 {
    top: 0%;
    left: 50%;
    transform: translateX(-50%);
}

#Link3 {
    top: 0%;
    right: 0%;
}

#Link3 .LinkContainer {
    left: 55%;
}


#Link4 {
    top: 50%;
    left: 0%;
}

#Link4 .LinkContainer {
    left: 45%;
}

#Link5 {
    top: 50%;
    left: 50%;
    transform: translateX(-50%);
}

#Link6 {
    top: 50%;
    right: 0%;
}

#Link6 .LinkContainer {
    left: 55%;
}

.eachLinkContainer {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 90%;
    height: 85%;
    transform: translate(-50%, -50%);
    backdrop-filter: blur(20px) brightness(100%);
    background-color: rgba(235, 235, 235, 0.5);
    border-radius: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    white-space: nowrap;
    user-select: none;
}

.shrink {
    width: 95%;
    height: 95%;
}

.eachLinkContainer:hover {
    width: 92%;
    height: 90%;
    background-color: rgba(255, 255, 255, 0.5);
    cursor: pointer;
    transition: all 0.2s ease;
}

.LinkImg {
    width: 35px;
    height: 35px;
}

.eachLinkContainer span {
    font-size: 20px;
}
</style>